define("ember-cli-notifications/templates/components/ecn-icon-close", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <svg ...attributes aria-hidden="true" focusable="false" role="img" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.29 12.783l-1.51 1.508a.708.708 0 01-1.005 0L8 10.518l-3.773 3.773a.712.712 0 01-1.008 0l-1.51-1.508a.712.712 0 010-1.007L5.482 8 1.709 4.227a.717.717 0 010-1.007l1.51-1.51a.712.712 0 011.008 0L8 5.484l3.775-3.776a.708.708 0 011.005 0l1.51 1.508c.279.279.28.73.003 1.01L10.518 8l3.772 3.776a.712.712 0 010 1.007z" fill="currentColor" fill-rule="nonzero"/></svg>
  */
  {
    "id": "+x32MTW4",
    "block": "[[[11,\"svg\"],[17,1],[24,\"aria-hidden\",\"true\"],[24,\"focusable\",\"false\"],[24,\"role\",\"img\"],[24,\"width\",\"16\"],[24,\"height\",\"16\"],[24,\"viewBox\",\"0 0 16 16\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[10,\"path\"],[14,\"d\",\"M14.29 12.783l-1.51 1.508a.708.708 0 01-1.005 0L8 10.518l-3.773 3.773a.712.712 0 01-1.008 0l-1.51-1.508a.712.712 0 010-1.007L5.482 8 1.709 4.227a.717.717 0 010-1.007l1.51-1.51a.712.712 0 011.008 0L8 5.484l3.775-3.776a.708.708 0 011.005 0l1.51 1.508c.279.279.28.73.003 1.01L10.518 8l3.772 3.776a.712.712 0 010 1.007z\"],[14,\"fill\",\"currentColor\"],[14,\"fill-rule\",\"nonzero\"],[12],[13],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "ember-cli-notifications/templates/components/ecn-icon-close.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});