define("ember-cli-notifications/templates/components/ecn-icon-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <svg ...attributes aria-hidden="true" focusable="false" role="img" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M13.714 0H2.286A2.286 2.286 0 000 2.286v11.428A2.286 2.286 0 002.286 16h11.428A2.286 2.286 0 0016 13.714V2.286A2.287 2.287 0 0013.714 0zM7 4.086c0-.472.529-.857 1-.857s1 .383 1 .857v4.571c0 .473-.527.857-1 .857s-1-.382-1-.857V4.086zm1 8.934a1.3 1.3 0 110-2.6 1.3 1.3 0 010 2.6z" fill="currentColor" fill-rule="nonzero"/></svg>
  */
  {
    "id": "LEChm+Fj",
    "block": "[[[11,\"svg\"],[17,1],[24,\"aria-hidden\",\"true\"],[24,\"focusable\",\"false\"],[24,\"role\",\"img\"],[24,\"width\",\"16\"],[24,\"height\",\"16\"],[24,\"viewBox\",\"0 0 16 16\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[10,\"path\"],[14,\"d\",\"M13.714 0H2.286A2.286 2.286 0 000 2.286v11.428A2.286 2.286 0 002.286 16h11.428A2.286 2.286 0 0016 13.714V2.286A2.287 2.287 0 0013.714 0zM7 4.086c0-.472.529-.857 1-.857s1 .383 1 .857v4.571c0 .473-.527.857-1 .857s-1-.382-1-.857V4.086zm1 8.934a1.3 1.3 0 110-2.6 1.3 1.3 0 010 2.6z\"],[14,\"fill\",\"currentColor\"],[14,\"fill-rule\",\"nonzero\"],[12],[13],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "ember-cli-notifications/templates/components/ecn-icon-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});