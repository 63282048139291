define("@ember/-internals/overrides/index", [], function () {
  "use strict";

  function once(callback) {
    var called = false;
    return function () {
      if (called) {
        return null;
      } else {
        called = true;
        return callback(...arguments);
      }
    };
  }

  undefined;
});